const { WebClient } = require("@slack/web-api");

const web = new WebClient(process.env.SLACK_TOKEN);
// The current date

const slackBot = async (message, type, detail) => {
  return new Promise(async function (resolve) {
    if (!type) {
      throw new Error('No "type" received in slackBot');
    }

    // console.log('Slackbot fired');
    const channel =
      type !== "error" && type !== "warning" ? type : "website-errors";

    // console.log(`[Slackbot] | ${message} | ${channel} | ${type}`);
    const forceSend = parseInt(process.env.FORCE_SEND_SLACK_MSGS);
    const symbols = {
      started: { text: "`STARTED` ⚪", colour: "#DCDCDC" },
      update: { text: "`UPDATE` 🟡", colour: "#FFD800" },
      success: { text: "`SUCCESS` ✅", colour: "#30b886" },
      "affiliate-clicks": { text: "`CLICK` 🎊", colour: "#30b886" },
      "activity-tagging": { text: "`ACTIVITY TAGGED` 🏷️", colour: "#30b886" },

      signups: { text: "`SIGN UP` 🎊", colour: "#30b886" },
      error: { text: "`ERRORED` 🔴", colour: "#a30300" },
      warning: { text: "`WARNING` 🟠", colour: "#FFA500" },
      restart: { text: "`RESTART` 🔄", colour: "#6A93B3" },
    };

    const symbol = symbols[type].text;
    // limit length of string to avoid rejection by Slack API
    const text = message.substring(0, 2500); //!type ? message : symbols[type] + " " + message;

    const blocks = [
      /*  {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text,
      },
    }, */
    ];
    if (detail) {
      // include a limit to ensure we don't go over the Slack API limit of 3001 (including the wrapping "")
      let processedDetail = JSON.stringify(detail);
      if (typeof processedDetail === "string") {
        processedDetail = processedDetail.substring(0, 2500);
      }

      blocks.push({
        type: "section",
        text: {
          type: "mrkdwn",
          text: `\`\`\`${processedDetail}\`\`\`` || null,
        },
      });
    }
    blocks.push({
      type: "context",
      elements: [
        {
          type: "mrkdwn",
          text: `${symbol} Message sent by geerly server: ${process.env.NEXT_PUBLIC_ENVIRONMENT}`,
        },
      ],
    });
    const attachments = [
      { fallback: text, color: symbols[type].colour, blocks },
    ];
    try {
      // Use the `chat.postMessage` method to send a message from this app

      if (process.env.NEXT_PUBLIC_ENVIRONMENT !== "dev" || forceSend) {
        await web.chat.postMessage({
          channel,
          attachments,
          text,
          // file: attachment,
        });
      } else {
        console.log(
          "[Development] | Prevented sending the following message to Slack as we are in the development environment"
        );
      }
      console.log("[SLACKED] " + symbol + " | " + text, detail || "");
    } catch (error) {
      console.error("ERROR POSTING TO SLACK: ", error);
      // Do not reject, we do not want to throw an error up the chain just because Slack didn't fire?
    }
    resolve();
  });
};

module.exports = slackBot;
