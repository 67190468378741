import { removeFields, removeItemsAtIndexes } from "@geerly/shared";
import cookie from "react-cookies";

import dynamic from "next/dynamic";
import React, { useEffect, useContext, createContext } from "react";
import Router from "next/router";
import useSWR from "swr";
import useSSR from "use-ssr";

import fetcher from "utils/fetcher.js";
import {
  USER_API_PATH,
  AUTHENTICATED_API_PATH,
  USER_API_PATH_FULL,
  IS_RESTRICTED_BETA,
} from "settings/global-website-config";

const BetaLogin = dynamic(() =>
  import("pages-sections/dynamicPage/BetaLogin.js")
);

const OnboardingTourProvider = dynamic(() =>
  import("pages-sections/dashboard/onboarding/OnboardingTourProvider.js")
);

/* target: productEntry,
            paths: ['product'],
            name: 'product',
            type: 'item',
            id: item.databaseId,
            additionalVars: {
              // field: 'linkedProduct',
              loggedInUserId: user.loggedInUserId,
              linkedProduct: productId,
            },
            event: {
              target: {
                value: createProduct(productId, product),
              },
            },
          }); */

export const parsePath = (opts) => {
  const {
    event,
    paths = [],
    user,
    target,
    keys,
    placeholderValue,
    shortcutValue,
    isArray,
    shouldAdd,
    idKey = "id",
    customRemoveIndex,
    getTarget,
    forceInt,
  } = opts;

  let { type, value } = event.target;

  let shortcutTarget;
  if (getTarget) {
    shortcutTarget = getTarget(user);
  } else if (target) {
    shortcutTarget = target;
  }

  if (isArray && shortcutTarget) {
    if (shouldAdd) {
      if (Array.isArray(value)) {
        shortcutTarget = shortcutTarget.concat(value);
      } else {
        shortcutTarget.push(value);
      }
    } else {
      const indexToRemove = shortcutTarget.findIndex((t) =>
        customRemoveIndex ? customRemoveIndex(t) : t[idKey] === value[idKey]
      );

      if (indexToRemove !== -1) {
        removeItemsAtIndexes(shortcutTarget, indexToRemove);
      }
    }
    if (shortcutValue) {
      value = shortcutValue;
    }
  } else {
    if (type === "number") {
      if (isNaN(value)) {
        throw new Error("NaN value supplied for type number:", value);
      }
      // input sometime sends Ints sas string!
      value = parseInt(value);
    }

    let target = shortcutTarget || user;
    if (shortcutValue) {
      value = shortcutValue;
    }
    for (let i = 0; i <= paths.length; i++) {
      const path = paths[i];
      if (path?.includes("{")) {
        const key = path.slice(1, -1); //Assumes only keys are {key}

        target = target.find((t) => t[key] === keys[key]);

        continue;
      }

      if (i !== paths.length - 1) {
        target = target[path];
      } else {
        if (type === "checkbox") {
          target[path] = !target[path];
          value = target[path];
        } else {
          if (
            (!value && value !== false) ||
            value == target[path] ||
            value === placeholderValue
          ) {
            // NB: Currently will not attempt to update an empty value; So you can't delete an email by deleting it out of the field, for example
            return; // No change, so don't mutate
          }
          target[path] = value;
        }
      }
    }
  }
  if (value && forceInt) {
    value = parseInt(value);
  }

  return value;
};

export function useUser({ full, redirectTo, redirectIfFound, noUser } = {}) {
  const [loggingOut, setLoggingOut] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isUpdatingError, setIsUpdatingError] = React.useState(false);

  const sendChangeToApi = async (optimisticData, payload, name) => {
    //return a;
    return new Promise(async (resolve) => {
      if (!payload.preventLoadingState) {
        setIsUpdating(true);
        setIsUpdatingError(false);
      }
      console.log("[useUser] | ***** sendChangeToApi called *****", payload);

      const fieldsToDelete = ["followOnInput", "notLiveYet"];

      removeFields(payload, fieldsToDelete);
      try {
        const res = await fetch(AUTHENTICATED_API_PATH + "/updateUser", {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // and specify the Content-Type
          },
          body: JSON.stringify({ payload }),
        });

        const { status } = res;
        const message = await res.json();

        if (status === 200) {
          console.log(
            "[useUser] | ***** Success status returned: ****",
            message,
            "status:",
            status
          );
        } else {
          console.error(
            "[useUser] | ***** Error status returned | Status:",
            status,
            "| Message:",
            message
          );
          setIsUpdatingError(name);

          // Don't reject errors as that will log the user out
        }
      } catch (error) {
        console.log("[useUser] | Caught Error:", error);
        setIsUpdatingError(name);
      }
      if (!payload.preventLoadingState) {
        setIsUpdating(false);
      }
      resolve(optimisticData);
    });
  };
  const { isBrowser } = useSSR();

  const url = full ? USER_API_PATH_FULL : USER_API_PATH;
  const urlToUse = !isBrowser || noUser ? null : url;

  const res = useSWR(urlToUse, {
    fetcher,
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateIfStale: false,
  });

  const { data, mutate, error, isValidating } = res;
  const loading = !data && !noUser;

  const user = data?.user;

  const finished = Boolean(data);
  const hasUser = Boolean(user);
  const shouldSync = data?.shouldSync;

  if (parseInt(process.env.NEXT_PUBLIC_SHOW_SWR_RESPONSE)) {
    console.log(
      "[UseUser] | SWR response in useuser | Full:",
      full,
      "|URL",
      url,
      "| Res:",
      res,
      "finished",
      finished,
      "hasUser",
      hasUser,
      "loggingout",
      loggingOut,
      "isValidating",
      isValidating,
      "data:",
      !!data
    );
  }
  useEffect(() => {
    if (!redirectTo || !finished) {
      return () => {};
    }
    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !hasUser) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && hasUser)
    ) {
      Router.push(redirectTo);
    }
  }, [redirectTo, redirectIfFound, finished, hasUser]);

  if (user) {
    if (process.env.SHOW_USER_OBJ) {
      console.log(
        "[useUser] | User found but not redirecting | loggedInUserId:",
        user.loggedInUserId
      );
    }
  }

  const handleUpdateUser = ({
    event,
    paths,
    keys,
    id,
    setLoadingState,
    name,
    target,
    placeholderValue, // Only present if the value hasn't been input and we are just pulling through from a higher order setting // Allows us to prevent saving a placeholder value
    additionalVars,
    addResultToAdditionalVars,
    additionalVarsTargetPath,
    type,
    isArray,
    shouldAdd,
    operation,
    shortcutValue,
    index,
    idKey,
    customRemoveIndex,
    getTarget,
    forceInt,
  }) => {
    let value = parsePath({
      event,
      paths,
      keys,
      user,
      target,
      placeholderValue,
      isArray,
      shouldAdd,
      shortcutValue,
      idKey,
      customRemoveIndex,
      getTarget,
      forceInt,
    });

    if (!value) {
      const { type } = event.target;
      if (type !== "checkbox") {
        if (type !== "number") {
          return;
        }
        value = 0;
      }
    }
    user.randomKey = Math.random(); // Horrible hack working around issue which is preventing re-render by making it recognisable as a new object

    const data = { user: { ...user } };
    const nameToUse = name || paths?.pop(); // Beware: removes last item. Shouldn't be an issue but just noting...
    if (
      (nameToUse === "distanceAlerts" || nameToUse === "priceAlerts") &&
      data.user.athleteProducts
    ) {
      for (const productEdge of data.user.athleteProducts.edges) {
        productEdge.settings[nameToUse] = value;
      }
    }
    const options = {
      optimisticData: data,
      rollbackOnError: true,
      revalidate: true,
    };

    if (setLoadingState && nameToUse) {
      const loadingStateName = index ? [nameToUse, index].join("-") : nameToUse;

      setLoadingState(loadingStateName);
    }

    let variables = {};
    if (id) {
      variables.id = id;
    }

    if (addResultToAdditionalVars && !additionalVars) {
      throw new Error("No additionalVars to wrap / add");
    }
    if (nameToUse) {
      if (addResultToAdditionalVars) {
        if (!additionalVarsTargetPath) {
          additionalVars[nameToUse] = value;
        } else {
          additionalVarsTargetPath[nameToUse] = value;
        }
      } else {
        variables[nameToUse] = value;
      }
    }

    if (additionalVars) {
      variables = { ...variables, ...additionalVars };
    }
    const payload = {
      operation: operation || "update", // Place at start so it can be overwritten by any other value
      type,
      variables,
    };
    mutate(sendChangeToApi(data, payload, nameToUse), options);
  };

  if (user) {
    if (user.refreshToken || user.accessToken) {
      // Just in case we get some issue with the server, have an extra check here and do not return user if creds leaked

      throw new Error(
        "Critical error #102 | This must be fixed immediately as it represents a security risk"
      );
    }
  }

  if (error) {
    if (parseInt(process.env.NEXT_PUBLIC_LOG_USE_USER_ERRORS))
      console.log("[useAuth] | Returning error", error);
  }
  return error
    ? [null, { error }]
    : [
        user,
        {
          error,
          loading,
          mutate,
          handleUpdateUser,
          isValidating,
          isUpdating,
          isUpdatingError,
          loggingOut,
          setLoggingOut,
          setIsUpdatingError,
          shouldSync,
          isBrowser,
          sendChangeToApi,
        },
      ];
}

// create useAuth hook

const authContext = createContext([]);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider({ children }) {
  const currentAccess = cookie.load("hasBetaAccess");
  const [hasBetaAccess, setHasBetaAccess] = React.useState(currentAccess);

  const [user, userProps] = useUser(); // This doesn't strictly need to be full user, but makes more sense than 2 round trips? TBD

  if (parseInt(process.env.NEXT_PUBLIC_SHOW_IF_USER_FOUND)) {
    console.log("[useUser] | Found User", Boolean(user));
  }

  if (
    IS_RESTRICTED_BETA &&
    !user &&
    !userProps?.loading &&
    hasBetaAccess !== "hasAccess"
  ) {
    return (
      <BetaLogin
        setHasBetaAccess={setHasBetaAccess}
        hasBetaAccess={hasBetaAccess}
      />
    );
  }

  return (
    <authContext.Provider value={[user, userProps || {}, hasBetaAccess]}>
      {children}
    </authContext.Provider>
  );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

const fullUserContext = createContext([]);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function FullUserProvider({ children }) {
  const [_user, { error }] = useAuth(); // This doesn't strictly need to be full user, but makes more sense than 2 round trips? TBD

  const [user, userProps] = useUser({ full: true, noUser: !_user });

  if (parseInt(process.env.NEXT_PUBLIC_SHOW_IF_USER_FOUND)) {
    console.log("[useUser] | Full User?", !!user, "| Basic user:", !!_user);
  }
  if (error) {
    userProps.error = error;
  }
  const values = [user, userProps || {}];

  if (
    user &&
    user.communicationPreferences &&
    (!user.communicationPreferences.hasHadTour ||
      parseInt(process.env.NEXT_PUBLIC_FORCE_SHOW_TOUR))
  ) {
    // Prevent bundling tour for most users
    return (
      <fullUserContext.Provider value={values}>
        <OnboardingTourProvider>{children}</OnboardingTourProvider>
      </fullUserContext.Provider>
    );
  }

  return (
    <fullUserContext.Provider value={values}>
      {children}
    </fullUserContext.Provider>
  );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useFullUser = () => {
  return useContext(fullUserContext);
};
